<template>
     <div class="preview_area show_sidebar workout_config" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <div class="section_container">
                    <div class="block_library">
                        <!-- <div class="lib_head">
                            <div class="filter_search">
                                <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="exerciseParams.search"/>
                                <button type="button" class="search_btn">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                            <button type="button" class="add_exercise" @click="newExercise = true;"><i class="fas fa-plus"></i>Create Custom Exercise</button>
                        </div> -->
                        <div class="result_wpr new">
                            <div class="actions">
                                <ul class="left">
                                    <li class="optionDrops contacts-tabs" @click="sortingList = !sortingList"  v-click-outside="closeSortingDropdown">
                                        {{ filterTitle }} <i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="sortingList ? 'active' : ''">
                                            <ul>
                                                <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                                    {{ filter.title }}
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="optionDrops contacts-tabs" @click="bulkOpt = !bulkOpt" v-if="selectedExercises.length" v-click-outside="closeBulkAction">
                                        Bulk Action<i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="bulkOpt ? 'active' : ''">
                                            <ul>
                                                <li @click="addToWorkout">Add To A Workout</li>
                                                <li @click="handleBulkFavorite()">Add To A Favorites</li>
                                                <li class="danger" v-if="selectedTab !== 1" @click="handleDeleteExercise(selectedExercises)">Delete</li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="right">
                                    <li class="search_area active">
                                        <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="exerciseParams.search"/>
                                        <button class="search_btn">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </li>
                                    <li class="optionDrops toggle_filter"><span @click="attributesFilter = !attributesFilter">{{attributesFilter ? 'Hide' : 'Show'}} Filter</span></li>
                                    <li class="optionDrops" v-if="selectedFilterTags.length"><span @click="selectedFilterTags = []">Clear Filter</span></li>
                                </ul>
                            </div>
                        </div>
                        <ul class="att_tags" v-if="selectedFilterTags.length">
                            <li v-for="(tag, t) in selectedFilterTags" :key="t" :class="tag.type">{{ tag.name }} <span class="delete_btn" @click="selectedFilterTags.splice(t, 1)"><i class="fas fa-times"></i></span></li>
                        </ul>
                        <div class="lib_head">
                            <ul class="tab_sec">
                                <li :class="selectedTab === 1 ? 'active' : ''" @click="handleSelectTab(1)">Thrive Exercises</li>
                                <li :class="selectedTab === 2 ? 'active' : ''" @click="handleSelectTab(2)">My Exercises</li>
                                <li :class="selectedTab === 3 ? 'active' : ''" @click="handleSelectTab(3)">My Favorites</li>
                                <li :class="selectedTab === 4 ? 'active' : ''" @click="handleSelectTab(4)">Shared Exercises</li>
                            </ul>
                            <div>
                                <button type="button" class="add_exercise"> Total Exercises: {{ exerciseParams.total ? exerciseParams.total : 0 }} &nbsp;&nbsp;| &nbsp;&nbsp;</button>
                                <button type="button" class="add_exercise" @click="newExercise = true;"><i class="fas fa-plus"></i>Create Custom Exercise</button>
                            </div>
                        </div>
                        <div class="content_panel">
                            <ul class="list exercises" id="exercises-container" v-if="workoutExercises.length">
                                <li v-for="(exercise, i) of workoutExercises" :key="i" :class="{ selected : selectedExercises.includes(exercise.id) }">
                                    <div class="action_items">
                                        <label :for="`library-item-${i}`" class="checkbox">
                                            <input type="checkbox" :id="`library-item-${i}`" :value="exercise.id" v-model="selectedExercises" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                        </label>
                                        <button type="button" class="pointer">
                                            <span class="icon"  @click="handleToggleFavorite(exercise)"> <i :class="exercise.favourite_count ? 'fas fa-heart' : 'far fa-heart'"></i></span>
                                            <span class="icon" @click="updateExercise = true; selectedExercise = exercise;" v-if="selectedTab === 2"><i class="far fa-edit"></i></span>
                                        </button>
                                    </div>
                                    <div class="video_wpr exercise"  @click="showVideo = true; selectedExercise = exercise;">
                                        <div class="video-overlay" @click="showVideo = true; selectedExercise = exercise;"></div>
                                        <!-- <span v-if="exercise.video_type == 1" class="player_wpr" v-html="parseEmbedCode(exercise.video_link)"></span>
                                        <span v-else-if="exercise.video_type == 2" class="player_wpr" v-html="exercise.embed_code"></span> -->
                                        <img :src="exercise.thumb" :alt="exercise.name">
                                    </div>
                                    <h6 v-tooltip="exercise.name"><label>{{ exercise.name }}</label></h6>
                                </li>
                                <infinite-loading class="infinite-loading" @infinite="handleWorkoutExercises" target=".exercises" :identifier="resetInfiniteWatcher" :top="false" ref="exercises-infinite-loading" />
                            </ul>
                            <div class="empty_section" v-else-if="!workoutExercisesLoader">
                                <h1 v-if="exerciseParams.attributes.length">Try using Body Part selection first, then narrow by Equipment Type to get best results.</h1>
                                <h1 v-else>Looks like you don't have any exercises yet.</h1>
                            </div>
                            <!-- <div class="load_more" v-if="workoutExercisesLoader">
                                <img src="@/assets/images/load_more.gif">Loading
                            </div> -->
                            <div class="exercise-quote-loader">
                                <quote-loader v-if="workoutExercises.length == 0 && workoutExercisesLoader" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <workout-attribute v-model="attributesFilter" title="Narrow Your Search" :selected-attibutes="exerciseParams.attributes" :toggle-attribute-select="handleSelectExerciseAttribute"/>
            <workout-attribute v-model="addAttributes" title="Attribute List" :selected-attibutes="exerciseParams.attributes" :toggle-attribute-select="handleSelectExerciseAttribute" :is-fixed="true" />
        </div>

        <div class="modal secondary video_player" v-if="showVideo">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="showVideo = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr">
                    <div v-if="selectedExercise.has_gif == 1 && selectedExercise.gif_image" class="player_wpr">
                        <img :src="selectedExercise.gif_image" :alt="selectedExercise.name">
                    </div>
                    <div v-else-if="selectedExercise.video_type == 1" class="player_wpr" v-html="parseEmbedCode(selectedExercise.video_link)"></div>
                    <div class="player_wpr" v-else-if="selectedExercise.video_type == 2" v-html="selectedExercise.embed_code"></div>
                </div>
            </div>
        </div>

        <new-exercise v-model="newExercise" :refresh-asset="refreshExercises" />
        <workout-setup v-model="workoutSetup" :selected-exercises="selectedExercises" :refresh-asset="refreshExercises" />
        <update-exercise  v-model="updateExercise" :selected-exercise="selectedExercise" :refresh-asset="refreshExercises"/>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const WorkoutAttribute = defineAsyncComponent(() => import('@/pages/workout/components/WorkoutAttribute'))
    const NewExercise = defineAsyncComponent(() => import('@/pages/workout/components/NewExercise'))
    const WorkoutSetup = defineAsyncComponent(() => import('@/pages/workout/components/WorkoutSetup'))
    const UpdateExercise = defineAsyncComponent(() => import('@/pages/workout/components/EditExercise'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import VideoParser from '@/utils/VideoParser'
    import InfiniteLoading from 'v3-infinite-loading'

    export default{
        name: 'Exercise Library',

        data () {
            return {
                selectedTab: 1,
                newExercise: false,
                bulkOpt: false,
                workoutSetup: false,
                addAttributes: false,
                sortingList: false,
                filterTitle: 'Sort By',
                updateExercise:false,
                filters: [
                    { title: 'A-Z', field: 'name', order: 'asc'},
                    { title: 'Z-A', field: 'name', order: 'desc'},
                    { title: 'Date Created', field: 'id', order: 'desc'},
                    { title: 'Last Modified', field: 'updated_at', order: 'desc'},
                ],
                selectedFilterTags:[],
                attributesFilter: true,
                exerciseParams: {
                    page: 1,
                    per_page: 12,
                    is_default: 1,
                    search: '',
                    attributes: [],
                    is_favourite: 0,
                    is_shared: 0,
                    order_by: 'id',
                    order: 'desc',
                },
                isTyping: false,
                selectedExercises: [],
                selectedExercise: {},
                showVideo: false,
                workoutExercises: [],
                resetInfiniteWatcher: 0,
            }
        },

        components: {
            WorkoutAttribute,
            NewExercise,
            WorkoutSetup,
            InfiniteLoading,
            UpdateExercise,
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.resetForm();

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            'exerciseParams.search' () {
                const vm = this;

                vm.exerciseParams.page = 1;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val && (vm.exerciseParams.search != null && (vm.exerciseParams.search.length >= 2 || vm.exerciseParams.search.length == 0))) {
                    vm.selectedExercises = [];
                    vm.resetInfiniteWatcher++;

                    vm.handleWorkoutExercises(undefined, true);
                }
            },

            selectedFilterTags: {
                handler (exerciseAttributes) {
                    const vm = this;

                    vm.exerciseParams.attributes = exerciseAttributes.map((attribute) => attribute.id);
                    vm.exerciseParams.page       = 1;

                    setTimeout(() => {
                        if (!vm.workoutExercisesLoader) {
                            vm.selectedExercises = [];
                            vm.resetInfiniteWatcher++;

                            vm.handleWorkoutExercises(undefined, true);
                        }
                    }, 10);
                },
                deep: true,
            }
        },

        mounted () {
            const vm = this;

            window.addEventListener('resize', vm.resizeEventHandler);
            vm.resizeEventHandler();
        },

        unmounted () {
            const vm = this;

            window.removeEventListener('resize', vm.resizeEventHandler);
        },

        computed: {
            ...mapState({
                workoutExercisesLoader: state => state.workoutModule.workoutExercisesLoader,
                addExercisesLoader: state => state.workoutModule.workoutBlockExerciseLoader,
                exerciseAttributes: state => state.workoutModule.exerciseAttributes,
            }),
        },

        methods: {
            ...mapActions({
                getWorkoutExercises: 'workoutModule/getWorkoutExercises',
                updateFavouriteExercise: 'workoutModule/updateFavouriteExercise',
                deleteWorkoutExercise: 'workoutModule/deleteWorkoutExercise',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            addToWorkout(){
                const vm = this;
                if(vm.selectedExercises.length){
                    vm.workoutSetup = true;
                }
            },

            sortingFilter (filter) {
                const vm      = this;

                if (vm.filterTitle != filter.title) {
                    vm.filterTitle             = filter.title;
                    vm.exerciseParams.order_by = filter.field;
                    vm.exerciseParams.order    = filter.order;
                    vm.selectedFilterTags      = [];
                    vm.selectedExercises       = [];
                    vm.exerciseParams.page     = 1;
                    vm.resetInfiniteWatcher++;

                    vm.handleWorkoutExercises(undefined, true);
                }
            },

            closeSortingDropdown () {
                const vm = this;

                vm.sortingList = false;
            },

            closeBulkAction(){
                const vm = this;

                vm.bulkOpt = false;
            },

            handleSelectExerciseAttribute (attributeId) {
                const vm        = this;
                const attribute = vm.exerciseAttributes.find(({id}) => id == attributeId);

                if (attribute) {
                    vm.handleSelectFilterTag (attribute);
                }
            },

            handleSelectFilterTag (attribute) {
                const vm = this;

                const hasFilter = vm.selectedFilterTags.findIndex((att) => att.id === attribute.id);

                if (hasFilter == -1) {
                    vm.selectedFilterTags.push(attribute);
                } else {
                    vm.selectedFilterTags.splice(hasFilter, 1);
                }
            },

            resetForm () {
                const vm = this;

                vm.selectedTab        = 1;
                vm.newExercise        = false;
                vm.bulkOpt            = false;
                vm.workoutSetup       = false;
                vm.addAttributes      = false;
                vm.sortingList        = false;
                vm.filterTitle        = 'Sort By';
                vm.selectedFilterTags = [];
                vm.attributesFilter   = true;
                vm.exerciseParams     = {
                                            page: 1,
                                            per_page: 12,
                                            is_default: 1,
                                            search: '',
                                            is_favourite: 0,
                                            is_shared: 0,
                                            order_by: 'id',
                                            order: 'desc',
                                        };
                vm.isTyping          = false;
                vm.selectedExercises = [];
                vm.resetInfiniteWatcher++;
                vm.updateExercise      = false;

                vm.handleWorkoutExercises(undefined, true);
            },

            handleSelectTab (tab) {
                const vm = this;

                if (vm.selectedTab != tab) {
                    vm.selectedTab             = tab;
                    vm.selectedExercises       = [];
                    vm.selectedFilterTags      = [];
                    vm.exerciseParams.search   = '';
                    vm.exerciseParams.order_by = 'id';
                    vm.exerciseParams.order    = 'desc';
                    vm.exerciseParams.page     = 1;

                    if (tab == 1) {
                        vm.exerciseParams.is_default   = 1;
                        vm.exerciseParams.is_favourite = 0;
                        vm.exerciseParams.is_shared    = 0;
                    } else if (tab == 2) {
                        vm.exerciseParams.is_default   = 0;
                        vm.exerciseParams.is_favourite = 0;
                        vm.exerciseParams.is_shared    = 0;
                    } else if (tab == 3) {
                        vm.exerciseParams.is_default   = 0;
                        vm.exerciseParams.is_favourite = 1;
                        vm.exerciseParams.is_shared    = 0;
                    } else if (tab == 4) {
                        vm.exerciseParams.is_default   = 0;
                        vm.exerciseParams.is_favourite = 0;
                        vm.exerciseParams.is_shared    = 1;
                    }

                    vm.resetInfiniteWatcher++;

                    vm.handleWorkoutExercises(undefined, true);
                }
            },

            handleToggleFavorite (exercise) {
                const vm     = this;
                const params = { ids: [exercise.id], favourite: exercise.favourite_count ? 0 : 1 };

                vm.updateFavouriteExercise(params).then((result) => {
                    if (result) {
                        vm.selectedExercises     = [];
                        exercise.favourite_count = exercise.favourite_count ? 0 : 1;
                    }
                });
            },

            refreshExercises () {
                const vm = this;

                vm.newExercise             = false;
                vm.bulkOpt                 = false;
                vm.workoutSetup            = false;
                vm.addAttributes           = false;
                vm.sortingList             = false;
                vm.exerciseParams.page     = 1;
                vm.exerciseParams.per_page = 12;
                vm.exerciseParams.search   = '';
                vm.selectedFilterTags      = [];
                vm.selectedExercises       = [];
                vm.resetInfiniteWatcher++;
                vm.updateExercise          = false;

                vm.handleWorkoutExercises(undefined, true);
            },

            handleBulkFavorite () {
                const vm = this;

                const params = { ids: vm.selectedExercises, favourite: 1 };

                vm.updateFavouriteExercise(params).then((result) => {
                    if (result) {
                        vm.selectedExercises   = [];
                        vm.exerciseParams.page = 1;
                        vm.resetInfiniteWatcher++;

                        vm.handleWorkoutExercises(undefined, true);
                    }
                });
            },

            handleDeleteExercise (ids) {
                const vm      = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover ${ ids.length > 1 ? 'these exercises' : 'this exercise'}. Please type DELETE to remove ${ ids.length > 1 ? 'these exercises' : 'this exercise'}.`, 'Delete');

                options.confirmButtonColor = '#eb1414';
                options.input = 'text';
                options.inputAttributes = { placeholder: 'Enter DELETE' };

                options.preConfirm = () => {
                                        return vm.deleteWorkoutExercise({ ids }).then((result) => {
                                            if (result) {
                                                vm.refreshExercises();
                                            }
                                        });
                                    }

                options.willOpen        = function () {
                                            const button  = Swal.getConfirmButton();
                                            const input   = Swal.getInput()

                                            if (button) {
                                                button.disabled = true;
                                            }

                                            input.oninput = (event) => {
                                                const text = event.target.value;

                                                if (/^[A-Z]/.test(text) == true && text === 'DELETE') {
                                                    Swal.enableButtons();
                                                } else {
                                                    button.disabled = true;
                                                }
                                            }
                                        }
                Swal.fire(options);
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url);
            },

            async handleWorkoutExercises ($state, force = false) {
                const vm = this;

                if ($state) {
                    vm.state = $state;
                }

                if (force) {
                    vm.workoutExercises = [];
                }

                if ((vm.exerciseParams.page <= vm.exerciseParams.last_page ) || force) {
                    if (!vm.workoutExercisesLoader) {
                        await vm.getWorkoutExercises(vm.exerciseParams).then((result) => {
                            if (result.data && result.data.length) {
                                vm.exerciseParams.last_page = result.last_page;
                                vm.exerciseParams.page      = result.current_page + 1;
                                vm.exerciseParams.total     = result.total;
                                vm.workoutExercises.push(...result.data);

                                if (vm.state != undefined) {
                                    vm.state.loaded();
                                }
                            } else {
                                if (vm.state != undefined) {
                                    vm.state.complete();
                                }
                            }
                        });
                    }
                } else {
                    if (vm.state) {
                        vm.state.complete();
                    }
                }
            },
            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 1351) {
                    vm.attributesFilter = false;
                } else {
                    vm.attributesFilter = true;
                }
            },
        }
    }
</script>

<style scoped>
.tab_sec{
    flex: 1 1 auto;
    margin-bottom: 1px;
    border: 0;
}
.tab_sec li{
    font-size: 13px;
}
.tab_sec li.active {
    border-radius: 5px;
}
.result_wpr.new{
    overflow: inherit;
    width: 100%;
}
.result_wpr.new .actions > ul li.optionDrops:first-child{
    padding-left: 0;
}
.result_wpr.new .actions > ul li.optionDrops:last-child{
    padding-right: 0;
}
.video_wpr {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    margin: 0;
    border-radius: 5px;
    overflow: hidden;
}
.video_wpr iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
}
.section_container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 50px 0 50px;
}
.content_panel{
    border-radius: 0;
    background: #fff;
    position: relative;
    padding: 30px;
    width: 100%;
}
.block_library{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 30px 0; */
    position: relative;
}
.block_library .lib_head{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    width: 100%;
}
.result_wpr.new .actions > ul li.search_area.active input[type=text] {
    padding: 0 15px;
    width: 300px;
}
/* .block_library .filter_search {
    height: 34px;
    border-radius: 20px;
    padding: 0 34px 0 0;
    position: relative;
    background: #fff;
    max-width: 400px;
    flex: 1 1 auto;
}
.block_library .filter_search input{
    font-size: 11px;
    line-height: 34px;
    color: #666;
    border: 0;
    width: 100%;
    padding: 0 15px;
    background: transparent;
    box-sizing: border-box;
}
.block_library .filter_search .search_btn {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 0;
    font-size: 11px;
    color: #333;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
} */
.add_exercise{
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
    color: #121525;
    cursor: pointer;
    padding: 12px 0;
}
.add_exercise i{
    margin-right: 5px;
    font-size: 9px;
}
.block_library ul.list{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.block_library ul.list > li{
    padding: 20px 10px;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;
}
.block_library ul li h6{
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight:500;
    text-align: center;
}
.block_library ul li h6 label{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.block_library ul li .action_items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 10px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.block_library ul li .action_items button{
    color: #777;
}
.block_library ul li .action_items button .icon{
    margin-left: 12px;
}
.block_library ul li:hover .action_items, .block_library ul li.selected .action_items{
    opacity: 1;
}
.block_library ul li .action_items label.checkbox span {
    width: 12px;
    flex: 0 0 12px;
    height: 12px;
    border-radius: 2px;
}
.block_library ul li .action_items label.checkbox span i{
    font-size: 8px;
}
/* .block_library ul li .action_items a{
    cursor: pointer;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
}
.block_library ul li .action_items a i{
    font-size: 9px;
    padding-right: 4px;
} */
.attribute_bar{
    padding: 15px 0;
    background: #fff;
    border-left: 1px solid #e9e9e9;
    width: 230px;
    max-height: 100vh;
    overflow-y: overlay;
    position: relative;
    margin-right: -230px;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction:column;
}
.attribute_bar.show{
    margin-right: 0;
}
.attribute_bar::-webkit-scrollbar{
    width: 4px;
}
.attribute_bar::-webkit-scrollbar-thumb{
    border-radius: 2px;
    background: #cacaca;
}
.attribute_bar h3{
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    padding: 0 20px 5px 20px;
    display: flex;
    align-items: center;
}
.attribute_bar h3 button{
    font-size: 13px;
    color: #999;
    margin-left: auto;
    cursor: pointer;
}
.toggle_btn{
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px 15px 20px;
}
.toggle_btn a{
    padding: 4px 15px;
    background: #f5f5f5;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
    display: block;
    border-radius: 10px;
    cursor: pointer;
}
.attribute_grp{
    border-top: 1px solid #f5f5f5;
}
.attribute_grp h4{
    padding: 12px 20px;
    font-size: 13px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.attribute_grp h4 i{
    font-size: 11px;
    color: #999;
    transition: all 0.3s ease-in-out;
}
.attribute_grp ul{
    padding: 0 15px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}
.attribute_grp.active ul{
    max-height: 10000px;
}
.attribute_grp.active h4 i{
    transform: rotate(-180deg);
}
.attribute_bar ul li{
    font-size: 13px;
    line-height: 18px;
    color: #5a5a5a;
    font-weight: 400;
    padding: 10px 15px;
}
.attribute_bar ul li:last-child{
    margin-bottom: 15px;
}
.attribute_bar ul li label.checkbox, .dropdown_wpr ul li label.checkbox{
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
}
.dropdown_wpr ul li label.checkbox span i{
    margin: 0;
}
.attribute_bar ul li label.checkbox span.active i, .dropdown_wpr ul li label.checkbox span.active i{
    transform: scale(1);
    opacity: 1;
}
.att_tags{
    /* background: #fff; */
    border-radius: 6px;
    padding: 0 20px 20px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}
.att_tags li{
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5a5a5a;
    position: relative;
}
.att_tags .delete_btn{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    background: #eb1414;
    color: #fff;
    position: absolute;
    right: -5px;
    top: -5px;
    cursor: pointer;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.att_tags li:hover .delete_btn{
    opacity: 1;
}
.att_tags li.exercise{
    border-color: #88d3d9;
}
.att_tags li.position{
    border-color: #b99191;
}
.att_tags li.contraction{
    border-color: #968bca;
}
.att_tags li.muscle{
    border-color: #76afff;
}
.att_tags li.movement{
    border-color: #cb72ff;
}
.att_tags li.equipment{
    border-color: #56e094;
}
.att_tags li.skeletal{
    border-color: #fb76c8;
}
.att_tags li.difficulty{
    border-color: #f2a31d;
}

.modal.new_exercise .modal_container{
    padding: 20px 30px 0 30px;
    max-height: 600px;
    overflow-y: scroll;
}
.modal.new_exercise .modal_container::-webkit-scrollbar{
    display: none;
}
.modal.new_exercise h2{
    margin-bottom: 5px;
    font-size: 22px;
    line-height: 28px;
    position: relative;
    width: 100%;
    padding: 0 20px;
    text-align: center;
}
.modal.new_exercise h2 .favorite_btn{
    position: absolute;
    left: 0;
    top: 5px;
    cursor: pointer;
    font-size: 16px;
    color: #777;
}
.modal.new_exercise h2 .close_btn{
    position: absolute;
    right: 0;
    top: 5px;
    cursor: pointer;
    font-size: 16px;
    color: #777;
}
.attribute_area{
    padding: 15px 0 10px 0;
}
.attribute_area h4{
    display: flex;
    align-items: center;
    padding-bottom: 6px;
    gap: 10px;
}
.attribute_area h4 label{
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #121525;
    margin-right: auto;
}
.attribute_area h4 a{
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #5a5a5a;
    cursor: pointer;
}
.attribute_area .att_tags{
    border: 1px solid #CBCDD3;
    border-radius: 6px;
    padding: 10px 15px;
    justify-content: flex-start;
    min-height: 50px;
}
.attribute_bar.fixed{
    position: fixed;
    right: -230px;
    top: 0;
    bottom: 0;
    z-index: 13;
    transition: all 0.3s ease-in-out;
}
.attribute_bar.fixed.show{
    right: 0;
}
.new_exercise .action_wpr{
    width: calc(100% + 60px);
    border-top: 1px solid #e9e9e9;
    background: #fff;
    padding: 20px 25px;
    margin: 0 -30px;
    position: sticky;
    bottom: 0;
}
.attribute_bar.fixed.show{
    right: 0;
}
.new_exercise .action_wpr{
    width: calc(100% + 60px);
    border-top: 1px solid #e9e9e9;
    background: #fff;
    padding: 20px 25px;
    margin: 0 -30px;
    position: sticky;
    bottom: 0;
}

#exercises-container {
    height: 60vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#exercises-container::-webkit-scrollbar {
    display: none;
}

.video-overlay {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    top: 0;
    cursor: pointer;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
}
@media(max-width:1199px){
    .result_wpr.new .actions > ul{
        margin: 0 0 15px;
        gap: 15px;
    }
}
@media(max-width: 991px){
    .result_wpr.new .actions > ul li.search_area.active input[type=text]{
        width: 200px;
    }
}
@media(max-width: 767px){
    .block_library ul.list > li{
        width: 33.333%;
    }
    .section_container{
        padding: 30px 40px;
    }
    .result_wpr.new .actions > ul li.toggle_filter{
        position: absolute;
        right: 0;
        top: 2px;
    }
    .result_wpr.new .actions > ul.right{
        width: 100%;
    }
    .result_wpr.new .actions > ul li.search_area{
        margin: 0 auto 0 0;
    }
    .result_wpr.new .actions > ul li.search_area input[type=text], .result_wpr.new .actions > ul li.search_area .search_btn{
        height: 30px;
    }
}
@media(max-width: 599px){
    .block_library ul.list > li{
        width: 50%;
    }
    .content_panel{
        padding: 20px;
    }
    .section_container{
        padding: 30px 15px 30px 20px;
    }
    .tab_sec{
        flex: 0 0 100%;
    }
    .add_exercise{
        order: -1;
    }
}
@media(max-width: 499px){
    .tab_sec{
        gap: 20px;
    }
    /* .add_exercise{
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    } */
    .att_tags{
        gap: 4px;
    }
    .att_tags li{
        padding: 3px 6px;
        font-size: 9px;
        line-height: 12px;
    }
}
@media(max-width: 420px){
    .block_library ul.list > li{
        width: 100%;
    }
}

:deep(.exercise-quote-loader .quote_wpr) {
    width: 100%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>